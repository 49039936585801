<template>
  <v-row class="mt-1">
    <v-col cols="12" md="4" class="mt-2">
      С каких номеров совершать вызов <span class="red--text">*</span>
      <v-tooltip right max-width="450px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded v-bind="attrs" icon v-on="on" class="ml-1" x-small><v-icon>mdi-help-circle</v-icon></v-btn>
        </template>
        <div class="text-caption">
          Выбранный номер будет отображаться на экране телефона.
          Номер выбирается из списка случайно
        </div>
      </v-tooltip>
    </v-col>
    <v-col cols="12" md="8" class="d-flex align-center">
      <div class="d-flex align-center justify-center pr-5">
        <span class="mr-2">общие</span>
        <v-switch v-model="isUserNumbers" hide-details class="ma-0"/>
        <span class="ml-1">свои</span>
      </div>
      <v-select
        :items="numbers"
        v-model="model"
        outlined
        hide-details
        dense
        multiple
        clearable
        label="Телефон"
        prepend-inner-icon="mdi-phone-forward"
      >
      <template v-slot:item="{ item }">{{ item | VueMaskFilter('(###) ###-#########') }}</template>
      <template v-slot:selection="{ item, index }">
        <span v-if="index < 1">{{  item | VueMaskFilter('(###) ###-#########') }}</span>
        <span
          v-if="index === 1"
          class="text-caption align-self-center ml-4"
        >
        +{{ value.length - 1}}
        </span>
      </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import { VueMaskFilter } from 'v-mask';

export default {
  name: 'SelectPhones',
  props: {
    value: Array,
  },
  data() {
    return {
      isUserNumbers: true,
    };
  },
  filters: {
    VueMaskFilter,
  },
  computed: {
    ...mapState({
      trashNumbers: state => state.trashNumbers.trashNumbers.filter(number => !number.match('800[0-9]{7,}')).sort(),
      userExternalNumbers: state => state.trashNumbers.userExternalNumbers.filter(number => !number.match('800[0-9]{7,}')).sort(),
    }),
    numbers() {
      return this.isUserNumbers ? this.userExternalNumbers : this.trashNumbers;
    },
    model: {
      get() {
        return this.value;
      },
      set(numbers) {
        this.$emit('input', numbers);
      },
    },
  },
  watch: {
    isUserNumbers() {
      this.$emit('input', '');
    },
  },
  methods: {
    returnNormNumber(item) {
      return `+!${item}`;
    },
  },
};
</script>
