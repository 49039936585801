import { actionTypes } from './components/steps/step2/actionTypes';

const isValidAutocall = autocall => {
  const {
    name, group, numbers, dateStart,
  } = autocall;
  return !!(name && group && numbers && dateStart);
};

const isValidActions = actions => {
  const {
    type,
    audio,
    ivrTts: {
      ivrExit,
      ivrTimeout,
      ivrOptions,
    },
    tts: {
      phrase,
      voice,
      emotion,
    },
    ivr: {
      num,
    },
  } = actions;

  if (type === actionTypes.AUDIO.name && !audio) {
    return false;
  }

  if (type === actionTypes.TTS.name && !(phrase?.trim() && voice?.trim() && emotion?.trim())) {
    return false;
  }

  if (type === actionTypes.IVR.name && !num) {
    return false;
  }

  if (type === actionTypes.IVR_TTS.name && !(ivrExit && ivrTimeout && Object.keys(ivrOptions).length)) {
    return false;
  }

  return true;
};

const isValidSchedule = schedule => {
  const {
    // autoRestart, expirationDatetime, tz,
    autoRestart, tz,
    mo, tu, we, th, fr, sa, su,
    start, stop,
  } = schedule;

  return !!(
    autoRestart
    // && expirationDatetime
    && tz !== null
    && (mo || tu || we || th || fr || sa || su)
    && start && stop
  );
};

const isValidData = ({ autocall, actions, schedule }) => (
  isValidAutocall(autocall)
  && isValidActions(actions)
  && isValidSchedule(schedule)
);

export {
  isValidAutocall,
  isValidActions,
  isValidSchedule,
  isValidData,
};
