export default {
  autocall: {
    name: '',
    comment: '',
    group: 'Default',
    numbers: null,
    dateStart: '',
    speed: 250,
    email: null,
    isEnableEmailReport: false,
  },
  schedule: {
    autoRestart: 'none',
    exceptions: '',
    // expirationDatetime: '',
    tz: Math.round((new Date().getTimezoneOffset() / 60) * -1),

    mo: true,
    tu: true,
    we: true,
    th: true,
    fr: true,
    sa: true,
    su: true,

    start: '10:00',
    stop: '20:00',
    pauseStart: null,
    pauseStop: null,
  },
  tts: {
    phrase: '',
    voice: 'oksana',
    emotion: 'good',
    speed: 1.2,
  },
  ivrTts: {
    ivrExit: 'hangup',
    ivrTimeout: 3,
    ivrOptions: {},
    isDetectSpeech: false,
    phrase: '',
    voice: 'ermil',
    emotion: 'neutral',
    speed: 1.3,
  },
};
