<template>
  <div class="mb-12">
    <v-stepper-step :step="3" :complete="currentStep > 3" :editable="isEditable || currentStep > 3">
      Настройка отправки
    </v-stepper-step>

    <v-stepper-content :step="3">

      <StartDatetime v-model="startDatetimeModel" :timezone="timezoneModel" />
      <!-- <StopDatetime v-model="stopDatetimeModel" :timezone="timezoneModel" /> -->

      <v-divider class="ma-5" ></v-divider>

      <SelectTime
        :weekDays.sync="weekDaysModel"
        :time.sync="timeForCallModel"
        :timezone.sync="timezoneModel"
      />

      <ExceptionsTime
        :time.sync="timeForCallModel"
      />

      <ExceptionsDates v-model="scheduleModel" />

      <PeriodRepeat v-model="scheduleModel" />

      <v-divider class="ma-5" ></v-divider>

      <SelectPhones v-model="phoneModel" />

      <Speed v-model="speedModel"/>

      <!-- <ExpirationDatetime v-model="expirationDatetimeModel" :start-date="startDatetimeModel" :timezone="timezoneModel" /> -->

      <CriteriaSuccessCall :criteria.sync="criteriaModel" :ivr-options="ivrOptions"/>

      <v-divider class="ma-5" ></v-divider>

      <EmailReport :autocall.sync="autocall" />

      <v-row>
        <v-col cols="12">
          <div class="d-flex mt-5">
            <v-btn outlined color="primary" dark class="mr-4" @click="$emit('prev')">Назад</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { typesCriteria } from '../../../criteria';
import SelectPhones from './components/SelectPhones.vue';
import SelectTime from './components/SelectTime.vue';
import StartDatetime from './components/StartDatetime.vue';
// import ExpirationDatetime from './components/ExpirationDatetime.vue';
import PeriodRepeat from './components/PeriodRepeat.vue';
import Speed from './components/Speed.vue';
import ExceptionsDates from './components/ExceptionsDates.vue';
import CriteriaSuccessCall from './components/CriteriaSuccessCall.vue';
import EmailReport from './components/EmailReport.vue';
import ExceptionsTime from './components/ExceptionsTime.vue';
// import StopDatetime from './components/StopDatetime.vue';

export default {
  name: 'AutocallStep3',
  components: {
    SelectPhones,
    SelectTime,
    StartDatetime,
    // ExpirationDatetime,
    PeriodRepeat,
    Speed,
    ExceptionsDates,
    CriteriaSuccessCall,
    EmailReport,
    ExceptionsTime,
    // StopDatetime,
  },
  props: {
    currentStep: Number,
    schedule: Object,
    autocall: Object,
    criteria: Object,
    ivrOptions: Object,
  },
  computed: {
    ...mapGetters(['WEEK_DAYS_NAME']),
    isEditable() {
      return !!(
        this.autocall.number
        && (this.weekDaysModel.start !== null && this.weekDaysModel.end !== null && this.schedule.start !== null && this.schedule.stop !== null)
        // && this.schedule.expirationDatetime !== null
        && this.schedule.autoRestart !== null
        && (this.criteria[typesCriteria.STATUS.id] || this.criteria[typesCriteria.MAX_BILL_SEC.id] || this.criteria[typesCriteria.DIGIT.id])
      );
    },
    autocallModel: {
      get() {
        return this.autocall;
      },
      set(v) {
        this.$emit('update:autocall', v);
      },
    },
    scheduleModel: {
      get() {
        return this.schedule;
      },
      set(schedule) {
        this.$emit('update:schedule', schedule);
      },
    },
    weekDaysModel: {
      get() {
        const week = this.WEEK_DAYS_NAME.reduce((arr, day, index) => (this.schedule[day.name] ? [...arr, index] : arr), []);

        return {
          start: week.length ? week[0] : null,
          end: week.length ? week[week.length - 1] : null,
        };
      },
      set(v) {
        const week = this.WEEK_DAYS_NAME.map((day, i) => ([
          [day.name],
          (i >= v.start && i <= v.end),
        ]));

        this.$emit('update:schedule', { ...this.schedule, ...Object.fromEntries(week) });
      },
    },
    timeForCallModel: {
      get() {
        return {
          start: this.schedule.start,
          stop: this.schedule.stop,
          pauseStart: this.schedule.pauseStart,
          pauseStop: this.schedule.pauseStop,
        };
      },
      set(time) {
        this.$emit('update:schedule', { ...this.schedule, ...time });
      },
    },
    // phoneModel: {
    //   get() {
    //     return this.autocall.number;
    //   },
    //   set(number) {
    //     this.$emit('update:autocall', { ...this.autocall, number });
    //   },
    // },
    phoneModel: {
      get() {
        return this.autocall.numbers;
      },
      set(numbers) {
        this.$emit('update:autocall', { ...this.autocall, numbers });
      },
    },
    startDatetimeModel: {
      get() {
        return this.autocall.dateStart;
      },
      set(dateStart) {
        this.$emit('update:autocall', { ...this.autocall, dateStart });
      },
    },
    stopDatetimeModel: {
      get() {
        return this.autocall.dateStop ? this.autocall.dateStop : '3000-01-01T00:00:00Z';
      },
      set(dateStop) {
        this.$emit('update:autocall', { ...this.autocall, dateStop });
      },
    },
    // expirationDatetimeModel: {
    //   get() {
    //     return this.schedule.expirationDatetime;
    //   },
    //   set(expirationDatetime) {
    //     this.$emit('update:schedule', { ...this.schedule, expirationDatetime });
    //   },
    // },
    timezoneModel: {
      get() {
        return this.schedule.tz;
      },
      set(tz) {
        this.$emit('update:schedule', { ...this.schedule, tz });
      },
    },
    speedModel: {
      get() {
        return this.autocall.speed;
      },
      set(speed) {
        this.$emit('update:autocall', { ...this.autocall, speed });
      },
    },
    criteriaModel: {
      get() {
        return this.criteria;
      },
      set(criteria) {
        this.$emit('update:criteria', criteria);
      },
    },
    pauseByErrorEmailModel: {
      get() {
        return this.autocall.email;
      },
      set(email) {
        this.$emit('update:autocall', { ...this.autocall, email });
      },
    },
    pauseByErrorModel: {
      get() {
        return this.autocall.isEnableEmailReport;
      },
      set(isEnableEmailReport) {
        this.$emit('update:autocall', { ...this.autocall, isEnableEmailReport });
      },
    },
  },
};
</script>
